import React, { Component } from "react";

import "./footer.css";

class Footer extends Component {
	render() {
		return (
            <footer className="footer">
                © {new Date().getFullYear()} Two Extranjeros
            </footer>
		)
	}
}

export default Footer;