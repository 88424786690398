import { Link } from "gatsby";
import React, { Component } from "react";

import "./header.css";

class Header extends Component {
	constructor(props) {
		super(props);
        this.state = {
            originalCategory: this.props.category || 0,
            currentCategory: this.props.category || 0,
            currentSubCategory: this.props.subCategory || 0,
            resettingCategory: false,
            mainCategories: null,
            secondaryCategories: null,
            subCategories: []
        };
	}

	componentDidMount() {
        this.setCategories();
    }
    
    updateCurrentCategory(event) {
        let updateState = this.state;
        updateState.resettingCategory = false;
        updateState.currentCategory = Number(event.target.getAttribute('data-wpid'));
        this.setState(updateState);
    }

    resetCurrentCategory(event) {
        var that = this;
        let updateState = this.state;
        updateState.resettingCategory = true;
        that.setState(updateState);
        setTimeout(function(){ 
            that.finishReset(Number(that.state.originalCategory));
        }, 300);
    }

    finishReset(cat) {
        if( this.state.resettingCategory ){
            let updateState = this.state;
            updateState.currentCategory = cat;
            updateState.resettingCategory = false;
            this.setState(updateState);
        }
    }

	setCategories(){
		let subCategories = [];

		this.props.data.primaryCategories.edges.map(node => {
            if( node.node.count > 0 ){
                return (
                    subCategories[node.node.wordpress_id] = []
                );
            } else {
                return null;
            }
		});

		this.props.data.secondaryCategories.edges.map(node => {
            if( node.node.count > 0 ){
                return (
                    subCategories[node.node.wordpress_parent].push({id: node.node.id, wordpress_parent: node.node.wordpress_parent, wordpress_parent_slug: node.node.parent_element.slug, wordpress_parent_name: node.node.parent_element.name, wordpress_id: node.node.wordpress_id, slug: node.node.slug, name: node.node.name})
                );
            } else {
                return null;
            }
		});

        this.setState({
            subCategories: subCategories
        });
	}
	
	render() {
		return (
			<header className="header">
				<section className="header__top">
					<div className="header__inner">
						<div className="header__logocontainer">
							<Link to="/blog" className="header__logo">T<span className="invisible">wo</span> E<span className="invisible">xtranjeros</span></Link>
						</div>
						<nav className="header__nav header__nav--primary">
							<Link to="/blog" className={"header__nav__item" + (( Number(this.state.currentCategory) === Number(0)) ? " current" : "" )} activeClassName="active" onMouseEnter={this.updateCurrentCategory.bind(this)} onMouseLeave={this.resetCurrentCategory.bind(this)} data-wpid={0}>All</Link>
                            {
                                this.props.data.primaryCategories.edges.map(node => {
                                    if( node.node.count > 0 ){
                                        return (
                                            <Link key={node.node.id} to={"/" + node.node.slug} className={"header__nav__item" + ( ( Number(this.state.currentCategory) === Number(node.node.wordpress_id)) ? " current" : "" )} activeClassName="active" onMouseEnter={this.updateCurrentCategory.bind(this)} onMouseLeave={this.resetCurrentCategory.bind(this)} data-wpid={node.node.wordpress_id}>{node.node.name}</Link>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                        
                            }
						</nav>
					</div>
				</section>
				<section className="header__bottom">
					<div className="header__inner">
                        <nav className={"header__nav header__nav--secondary" + (this.state.currentCategory === 0 ? " header__nav--active" : "" )} onMouseEnter={this.updateCurrentCategory.bind(this)} onMouseLeave={this.resetCurrentCategory.bind(this)} data-wpid={0}><Link to="/blog" className={"header__nav__item" + (this.state.currentCategory === 0 ? " header__nav--active" : "" )} activeClassName="active">All Posts</Link></nav>
                        { 
                            this.state.subCategories.map(node => {
                                return (
                                    <nav key={node[0].id} className={"header__nav header__nav--secondary" + (this.state.currentCategory === node[0].wordpress_parent ? " header__nav--active" : "" )} onMouseEnter={this.updateCurrentCategory.bind(this)} onMouseLeave={this.resetCurrentCategory.bind(this)} data-wpid={node[0].wordpress_parent}>
                                        <Link to={"/" + node[0].wordpress_parent_slug} className={"header__nav__item" + (this.props.subCategory === node[0].wordpress_parent ? " active" : "") } activeClassName="active" data-wpid={node[0].wordpress_parent}>All {node[0].wordpress_parent_name}</Link>
                                        {
                                            node.map(item => {
                                                return (
                                                    <Link key={item.id} to={"/" + item.wordpress_parent_slug + "/" + item.slug} className={"header__nav__item" + (this.state.currentSubCategory === item.wordpress_id ? " active" : "" )} data-wpid={node[0].wordpress_parent} activeClassName="active">{item.name}</Link>
                                                );
                                            })
                                        }
                                    </nav>
                                );
                            })
                        }
					</div>
				</section>
			</header>
		)
	}
}

export default Header;