import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
    const data = useStaticQuery(graphql`
		query missingQuery {
            site {
                siteMetadata {
                    title
                }
            }
            primaryCategories: allWordpressCategory(filter: {wordpress_parent: {eq: 0}, wordpress_id: {ne: 1}}, sort: {fields: name}) {
                edges {
                    node {
                        count
                        id
                        slug
                        name
                        wordpress_parent
                        wordpress_id
                        parent_element {
                            slug
                            name
                        }
                    }
                }
            }
            secondaryCategories: allWordpressCategory(filter: {wordpress_parent: {ne: 0}}, sort: {fields: name}) {
                edges {
                    node {
                        count
                        id
                        slug
                        name
                        wordpress_parent
                        wordpress_id
                        parent_element {
                            slug
                            name 
                        }
                    }
                }
            }
		}
    `);

    return(
        <Layout data={data}>
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
            <p>Sorry, that page doesn't exist!</p>
        </Layout>
    );
  
};

export default NotFoundPage;
