import React, { Component } from "react";
import Helmet from "react-helmet";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

export default class Layout extends Component {
    constructor(props) {
		super(props);
        this.state = {
            
        };
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <link href="https://fonts.googleapis.com/css?family=Cabin:700|Lora:400,400i,700,700i&display=swap" rel="stylesheet" />
                </Helmet>
                <Header siteTitle={this.props.data.site.siteMetadata.title} category={ (this.props.category && this.props.category.wordpress_id) ? this.props.category.wordpress_id : 0} data={this.props.data} subCategory={(this.props.subCategory && this.props.subCategory.wordpress_id) ? this.props.subCategory.wordpress_id : 0} />
                <main className="main">{this.props.children}</main>
                <Footer />
            </>
        );
    }
}